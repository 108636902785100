import React from 'react'
import Header from '../header/Header'
import Hero from '../hero/Hero'

export function Articles(){
    return(
        <main>
            <Header /> 
            <Hero />
            <h1>Oie</h1>
        </main>
    )
}